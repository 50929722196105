.wrapper{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: beige;
}
.container{
    max-width: 968px;
    height: 3rem;
    margin: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
ul{
 list-style: none;
}

a{
    text-decoration: none;
    color: black;
}


.head,.toggle{
    font-weight: 500;
    font-size: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .menu{
        position: fixed;
        bottom:100%;
        /* to be shown bottom 0 */
        left: 0;
        width: 100%;
        background-color: bisque;
        padding: 2rem .2rem 4rem;
        box-shadow: 0 1px 4px rgba(0,0,0,.15);
        border-radius: 1.5rem 1.5rem 0 0 ;
        transition: .3s;
    }    
}
.list {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin-left: -2.5rem;
    gap: 2rem;

}

.link{
    display: flex;
    flex-direction: column;
    align-items: center;
    font: 1.2rem;
    font-weight: 100;
}

.icon{
    font-size: 1.5rem;
}
.closeBTN{
    position: absolute;
    right: 1.3rem;
    bottom: .5rem;
    font-size: 1.5rem;
    cursor: pointer;
}


.wrapper{
    position: fixed;
   }

.line{
    border-left:5px solid #5000F1;
    /* #00F1C9 */
    height: 150px;
    margin-left: 2.5rem;  
}

.sMIcons{
    display: flex;
    flex-direction: column;
    margin:0rem 0 0 1.3rem;    
}

.sMIcons>a{
    margin: 0.5rem 0;
    padding: 6.3px 7px 2px 7px;
    text-decoration: none;
    font-size: 1.5rem;
    border: 2px solid #5000F1;
    border-radius: 50%;
   color:linear-gradient(1800deg,#5000F1,#00F1C9);
}

.sMIcons>a:hover {
    color: white;
    background:linear-gradient(90deg,#6a33da,#87f8e5);
}

@media screen and (max-width: 480px) {
    .wrapper{
        position: absolute;
        margin-left:-5px;
        z-index: 0;
    }
    .line{
        height: 180px;
    }
  
}    
.wrapper{
    margin: 2rem;
    padding: 2rem 4rem;
    gap: 2rem;
    min-height: 90vh;
}  
.cIMG{
    width: 400px;
    height: 260px;
    border: 2px solid #5000F1;
    box-shadow: 0 2px 0.5px 0 #00F1C9, 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    margin: 1rem;
}

/* .cIMG:hover{
    width: 750px;
    height: 500px;
} */
.display{
 margin-top: 5rem;
}

.display>:nth-child(5){
filter: blur(5px);
}

@media screen and (max-width: 610px) {
    .display{
        margin-top: 2rem;
        width: 100%;
       }
       
        .cIMG{
            width: 400px;
            height: 280px;
            border: 2px  solid  purple;
            margin: 0.5rem;
        }

    }


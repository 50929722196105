.App {
 width: 100%;
 /* overflow-y:hidden ; */
 overflow-X: hidden;
  bottom: 0;
  
}
::-webkit-scrollbar {
  display: none;
}
.displayAPP{
 display: flex;

}
.SocialMedia{
  width: 5%;
}
.Home{
  width: 90%;
}
@media screen and (max-width: 767px) {  
 .Email{
  display: none;
 }
 .Home{
  width: 100%;
 }
 
}

@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@700&display=swap');
.wrapper{
     /* margin-top: 20px; */
     /* height: 160px; */
    text-align: center;
    z-index: 1;
    background:linear-gradient(120deg,#a980fa,#aafcef);
    box-shadow: 5px 5px 10px 10px #888888;
    /* padding: -10rem 0; */
}   
.sgSignature>img{
    margin: -40px 0 0 45px;
    transform: scale(0.6);
}
.title{
  /* margin-top: -30px; */
  font-family: 'Fuzzy Bubbles', cursive;
}

@media screen and (max-width: 415px) {
 /* .sgSignature{
    height: 100px;
 } */
    .sgSignature>img{
        margin: -50px 0 0 -30px;
        transform: scale(0.4);
    }
}


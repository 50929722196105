.fixedTop {
    position: absolute;
    overflow-y: hidden;
    width: 1200px;
    height: 700px;
    /* background: #00F1C9; */
    background:linear-gradient(70deg,#7a40ec,#3ef2d4);
    border-radius: 22px;
    z-index: 9;
    margin-top: -330px;
    margin-left: 510px;
    box-shadow: 5px 5px 20px 10px #888888;
  }

.wrapper{
    margin: 4rem 2rem;
    padding: 17rem 2rem 5rem 2rem;
    gap: 1rem;
}
.codeIMG{
    margin-left: 15%;
    /* transform: scale(1.5); */
    position: absolute;
    padding-left: 32%;
    margin-top: -150px;
    color: red;
}

/* .codeIMG>img:hover{
    transform: scale(1.5);
} */
.title{
    letter-spacing: 1px;
    font-size:1.6rem;

    /* opacity: 0.7; */
}

.name{
    text-rendering: optimizeLegibility;
    transition: all 300ms cubic-bezier(0.68, -0.1, 0.265, 1.55);
    /* color: #00F1C9; */
    margin-top: 0.5rem;
    letter-spacing: 1.5Px;
    font-size: 7rem;
    font-weight:500 ;
    /* color: #6    85B7; */
    text-shadow: 
      1px 0px 1px #ccc, 0px 2px 2px #eee, 
      2px 1px 1px #ccc, 1px 4px 2px #eee,
      3px 2px 1px #ccc, 2px 6px 2px #eee,
      4px 3px 1px #ccc, 3px 8px 2px #eee;

}

.intro{
    margin-top: 1rem;
    font-size:2rem;
    font-weight: 700;
    color: #888888;
    /* width: calc(100% - 40%); */
}

.btn{
    display: flex;
    margin-top: 2rem;
    padding: 0.8rem 1rem ;
    width: 140px;
    border: 2px solid #6850B7;
    border-radius: 10px;
    background:linear-gradient(90deg,#8249f3,#98efe0);
    
  
}
.btn:hover{
    background:linear-gradient(1800deg,#5000F1,#00F1C9);

}
.btnTitle{
    margin: 0.1rem;
    font-weight: 700;
}
.dIcon{
   font-size: 1.5rem;
   margin-left: 8px;
}

@media screen and (max-width: 1275px) {
    
    .name{font-size: 6rem;}
    .fixedTop {
        width: 1000px;
        height: 800px;
        margin-top: -510px;
        margin-left: 360px;
    }
}  
@media screen and (max-width: 940px) {
    .title{font-size: 1.2rem;}
    .name{font-size: 4.5rem;}
    .fixedTop {
        width: 800px;
        height: 600px;
        margin-top: -320px;
        margin-left: 280px;
    }
}

@media screen and (max-width: 690px) {
    .title{font-size: .8rem;}
    .name{font-size: 4rem;}
    .intro{font-size: 1.5rem;}
    .fixedTop {
        width: 700px;
        height: 500px;
        margin-top: -260px;
        margin-left: 145px;
    }

}  

@media screen and (max-width: 480px) {
    .fixedTop {
        width: 600px;
        height: 600px;
        margin-top: -310px;
        margin-left: 50px;
        
    }
    .wrapper{
        margin: 6px;
        padding: 25rem 1rem 5rem 2rem;
        gap: 1rem;
    }
    .title{font-size: 1rem;}
    .name{
        font-size: 2.8rem;
        font-weight: 700; 
        text-shadow: none;  
    }
    .intro{width:100% ;}
    .btn{
          margin-top: 4rem;
          padding: 0.3rem 0.5rem ;
          width: 140px;
    }
}  

/* Phone */

@media screen and (max-width: 415px) {
    .fixedTop {
        width:600px;
        height: 500px;
        margin-top: -220px;
        margin-left: -20px;
        background:linear-gradient(60deg,hsl(260, 87%, 56%),#21f8d4);
    }
    .wrapper{
        padding:37rem 1rem 6rem 1rem ;
        z-index: 0;
    }
    .name{
       margin: 0.4rem 0 -0.8rem 0 ;         
    }
    .btn{
        margin-top: 1.4rem;
        padding: 0.4rem 0.8rem 0.3rem 0.8rem ;
        width: 140px;
    }
    
}

@media screen and (max-width: 394px) {
     .fixedTop { 
        margin:-240px 0 0 -40px ;} 
     .name{font-size: 2.6rem;}
    }       

    @media screen and (max-width: 376px){
        .fixedTop { 
            margin:-240px 0 0 -50px ;} 
            .wrapper{
                padding:28rem 1rem 6rem 1rem ;
            }
            .name{
                margin: .4rem 0 -.6rem 0 ;
            }
    }

    @media screen and (max-width: 361px){
        .fixedTop { 
            margin:-260px 0 0 -66px ;}
        .name{
            font-size: 2.4rem;
        }
    }
.wrapper{
 position: fixed;
 margin-top: 440px; 
 margin-left: 10px;
 z-index: 0;
}

.text{
    writing-mode: vertical-rl;

}
.text>h5>a{
    text-decoration: none;
    /* color: #A460ED; */
    color: #5000F1;

    margin-left: -10.5rem;
}
.text>h5>a:hover{
    font-weight: bolder;
}
.line{
    border-left:5px solid #5000F1;
    height: 370px;
    margin-left: 1.5rem;
    margin-top:1rem;  
}


.wrapper{
 display: flex;
 flex-direction: row;
 margin: .5rem 1rem;
 min-width: 200px;
 max-height: 100px;
 padding: 7px 10px;
 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 border-radius: 10px;
}

.skillIcon{
    margin-left: 15px;
    font-size: 40px;
}

.title{
    font-size: 20px;
    font-weight:500;
    margin: 5px 0 0 15px;
}

@media screen and (max-width: 610px) {

    .title{
        font-size: 20px;
        font-weight:500;
        margin: 5px 0 0 85px;
    }
}


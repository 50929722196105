@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.wrapper{
    margin: 2rem 8rem;
    padding: 2rem 2rem 2rem 0;
    gap: 2rem;
    min-height: 90vh;
}   
.title{
    margin-bottom: 3.5rem;
    font-size: 3rem;
    font-family: 'Roboto', sans-serif;
   
}
.aboutIcon{
    margin-top: 20px;
}
.skill{
    margin-top: 2rem;
    margin-left: -2rem;
    padding: 0 1.5rem;
    display: grid;
    grid-template-columns: repeat(2,1fr);
}

.skillGridIcon{
    font-size: 70px;
}
.aboutWrapper{
    display: flex;
    flex-direction: row;
}
.data{
  width: 60%;
}
.text{
    font-size: 1.2rem;
}


/* Image */
.sgIMG{
    margin-left: 5%;
   transform: scale(0.95);
   overflow: hidden;
    border-radius: 15% 15% 15% 1%;
    border: 2px solid #5000F1;
    box-shadow: 0 2px 4px 0 #00F1C9, 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  } 
  .sgIMG>img {
   margin-top: -120px;
   transform: scale(1.5);
  }
  @media screen and (max-width: 1000px) 
  { 
     .aboutWrapper{
    display: flex;
    flex-direction: column-reverse;
    }
  }
@media screen and (max-width: 610px) {
    .title{
        font-size: 2.5rem;
    }
    
    .skill{
        margin: 2rem 0 2rem;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
  
    .data{
        width: 100%;
    }
    .text{
        margin-top: 2rem;
        text-align: center;
        width: 100%;
        /* margin:0 5rem; */
        font-size: 1.2rem;
        }
     
 
}
/* Phone */
@media screen and (max-width: 415px) {
    .wrapper{
        margin: 2rem 1rem;
        padding: 2rem 0;  
    } 
    .data{
        padding: 0;    
    }
    /* Image */
.sgIMG{
    margin-left: 0;
    border-radius: 20% 20% 1% 20%;
    transform: scale(1);
}
.sgIMG>img {
    margin: -120px 0 0 -30px;
   }
}
